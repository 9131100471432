h1, p {
  font-family: Lato;
}

p {
  font-size: x-large;
}

html {
  background-color: #92E4D1;
}

div#root {
  height: 100vh;
  width: 100%;
  text-align: center;
}

div#container {
  height: 30vh;
}

div#screenshotcontainer {
  display: inline-block;
  height: 70vh;
}

#privacy {
  text-align: left;
}

.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50px;
  height: 50px;
  border-radius: 6px;
}

.appstore_button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 60px;
}

.screenshots {
  /* display: block; */
  /* margin-left: auto; */
  /* margin-right: auto; */
  width: auto;
  height: 100%;
}